import axios, { AxiosResponse } from 'axios'
import { BACKEND_ENDPOINT } from '../../constants'

const BASE_URL = `${BACKEND_ENDPOINT}/api`

export async function escrowReserveTicket(
  ticketId: number,
  quantity: number,
): Promise<AxiosResponse<number>> {
  return axios
    .get<number>(
      `${BASE_URL}/Escrow/Reserve?ticketid=${ticketId}&quantity=${quantity}`,
    )
    .then((response) => response)
    .catch((error) => {
      console.log(error)
      return error
    })
}

export async function escrowReleaseTicket(
  escrowId: number,
  quantity: number,
): Promise<AxiosResponse> {
  return axios
    .get<number>(
      `${BASE_URL}/Escrow/Release?escrow_id=${escrowId}&quantity=${quantity}`,
    )
    .then((response) => response)
    .catch((error) => {
      console.log(error)
      return error
    })
}
