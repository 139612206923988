import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntent, StripeCardElementOptions } from '@stripe/stripe-js'
import React, { useEffect, useState } from 'react'
import { API, useEvent } from '../../utils'
import * as Sentry from '@sentry/react'
import { default as InputFieldStandard } from '../InputField'

import ProceedButton from '../ProceedButton'
import { useProcessing } from '../../utils/contexts/ProcessingContext'
import { RouteComponentProps } from 'react-router'
interface StripeCardFormProps {
  onComplete: (paymentResponse: {
    billing: IBillingDetails
    paymentIntent: PaymentIntent
  }) => void
  selectedTicket: ITicket
  selectedQuantity: number
  routeProps: RouteComponentProps
}

const InputField = (props: {
  label: string | undefined
  id: string | undefined
  type: string | undefined
  placeholder: string
  required: boolean | undefined
  autoComplete: string | undefined
  value: string | number | string[] | undefined
  onChange: React.ChangeEventHandler | undefined
  disabled?: boolean
}) => (
  <div className="form-row">
    <InputFieldStandard {...props} />
  </div>
)

const CARD_OPTIONS: StripeCardElementOptions = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#0500F2',
      color: '#fff',
      fontWeight: 400,
      fontFamily: '"Basier Square", sans-serif',
      fontSize: '14px',
      '::placeholder': {
        color: 'rgba(var(--primary-colour),0.75)',
      },
    },
    invalid: {
      iconColor: '#AF0606',
      color: '#AF0606',
    },
  },
}

function StripeCardForm(props: StripeCardFormProps): React.ReactElement {
  const stripe = useStripe()
  const elements = useElements()
  const [clientSecret, setClientSecret] = useState<string>('')
  const [error, setError] = useState<string | false>(false)
  const [cardComplete, setCardComplete] = useState<boolean>(false)
  // const [processing, setProcessing] = useState<boolean>(true)
  const { processing, setProcessing } = useProcessing()
  const [billingDetails, setBillingDetails] = useState<IBillingDetails>({
    email: '',
    phone: '',
    name: '',
  })
  const { promoCode } = useEvent().eventState
  useEffect(() => {
    setProcessing(true)
    API.post<string>(
      `Payments/Add?ticket_id=${props.selectedTicket.ID}&quantity=${
        props.selectedQuantity
      }${promoCode && promoCode !== '' ? `&promo_code=${promoCode}` : ''}`,
    ).then(
      (response) => {
        setClientSecret(response.data)
        setProcessing(false)
      },
      (reqError) => {
        Sentry.captureException(reqError)
      },
    )
  }, [props.selectedTicket, props.selectedQuantity, API])

  useEffect(() => {
    if (clientSecret === '') {
      API.post<string>(
        `Payments/Add?ticket_id=${props.selectedTicket.ID}&quantity=${props.selectedQuantity}`,
      ).then(
        (response) => {
          console.log(response.data)
          setClientSecret(response.data)
          setProcessing(false)
        },
        (reqError) => {
          Sentry.captureException(reqError)
        },
      )
    }
  }, [clientSecret])

  async function handleSubmit(e: React.MouseEvent) {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    if (error) {
      elements.getElement('card')?.focus()
      return
    }
    if (cardComplete) {
      setProcessing(true)
    }

    const element = elements.getElement(CardElement)
    if (element === null) {
      return Sentry.captureException('Card Element not found') // Log to sentry
    }

    const payment_method = await stripe.createPaymentMethod({
      type: 'card',
      card: element,
      billing_details: billingDetails,
    })
    if (payment_method.error) {
      switch (payment_method.error.code) {
        case 'parameter_invalid_empty':
          // Sentry.captureException(new Error('parameter_invalid_empty'), {
          //   tags: {
          //     error: payment_method.error.toString(),
          //     billingDetails: billingDetails.toString(),
          //   },
          // })
          switch (payment_method.error.param) {
            case 'billing_details[name]':
              setError('Please enter a valid cardholder name')
              break
            case 'billing_details[email]':
              setError('Please enter a valid cardholder email')
              break
            case 'billing_details[phone]':
              setError('Please enter a valid cardholder phone')
              break
            default:
          }
          break
        default:
          setError(
            payment_method.error.message ? payment_method.error.message : false,
          )
          break
      }
      setProcessing(false)
      return
    }
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: payment_method.paymentMethod?.id,
    })
    setProcessing(false)
    if (payload.error) {
      setError(`Payment Failed ${payload.error.message}`)
    } else {
      // Successful Payment
      if (payload.paymentIntent.status === 'succeeded') {
        props.onComplete({
          billing: billingDetails,
          paymentIntent: payload.paymentIntent,
        })
      } else if (payload.paymentIntent.status === 'requires_action') {
        setError('Some more action is required')
        // Sentry.captureException(new Error('require_action'), {
        //   tags: {
        //     error: payload.paymentIntent.status,
        //     billingDetails: billingDetails.toString(),
        //   },
        // })
      } else {
        setError('Some other error occurred')
        reset()
      }
    }
  }

  const reset = () => {
    setError(false)
    setBillingDetails({
      email: '',
      phone: '',
      name: '',
    })
  }

  if (clientSecret !== '') {
    return (
      <>
        <form id="formBilling" style={{ paddingBottom: '10px' }}>
          <InputField
            label="Cardholder Name"
            id="name"
            type="text"
            placeholder="John Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setError(false)
              setBillingDetails({
                ...billingDetails,
                name: target !== null ? target.value : '',
              })
            }}
            disabled={processing}
          />
          <InputField
            label="Email"
            id="email"
            type="email"
            placeholder="example@email.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setError(false)
              setBillingDetails({
                ...billingDetails,
                email: target !== null ? target.value : '',
              })
            }}
            disabled={processing}
          />
          <InputField
            label="Phone"
            id="phone"
            type="tel"
            placeholder="01 123 4567"
            required
            autoComplete="phone"
            value={billingDetails.phone}
            onChange={(e) => {
              const target = e.target as HTMLTextAreaElement
              setError(false)
              setBillingDetails({
                ...billingDetails,
                phone: target !== null ? target.value : '',
              })
            }}
            disabled={processing}
          />
          {error && <div className="error-message">{error}</div>}
          <div className="form-row" style={{ marginTop: '15px' }}>
            <div
              className="form-field"
              style={{ padding: '15px 5px', paddingLeft: '10px' }}
            >
              <CardElement
                options={CARD_OPTIONS}
                onChange={(e) => {
                  setError(e.error ? e.error.message : false)
                  setCardComplete(e.complete)
                }}
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!cardComplete}
            onClick={(e) => (cardComplete ? handleSubmit(e) : false)}
            style={{ display: 'none' }}
          />
        </form>
        <ProceedButton
          blocked={!cardComplete || error !== false}
          onClick={(e) => (cardComplete ? handleSubmit(e) : null)}
        >
          Pay
        </ProceedButton>
      </>
    )
  } else return <>Loading</>
}

export default StripeCardForm
