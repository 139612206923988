import * as React from 'react'
import { API, useEvent } from '../../utils'

export interface IPromoCodeCheckProps {
  onApplyPromo: (code: string) => void
}

export function PromoCodeCheck(props: IPromoCodeCheckProps) {
  const [promoCode, setPromoCode] = React.useState('')
  const [promoCodeValid, setPromoCodeValid] = React.useState(false)
  const [promoCodeError, setPromoCodeError] = React.useState<string>()
  const { event } = useEvent().eventState
  async function handleApply() {
    API.get<IPromoCodeResponse>(
      `PromoCode/Check?code=${promoCode}${
        event?.ID ? `&event_id=${event?.ID}` : ''
      }`,
    ).then((response) => {
      console.log(response.data)
      if (response.data.IsValid) {
        setPromoCodeValid(true)
        props.onApplyPromo(promoCode)
      } else {
        console.log(response.data.Reason)
        setPromoCodeError(response.data.Reason)
        setPromoCodeValid(false)
      }
    })
  }
  return (
    <div className="eventPromoCodeCheck innerWidth">
      <form
        className="formPromoCodeCheck"
        onSubmit={(e) => {
          e.preventDefault()
          handleApply()
        }}
      >
        <div className="form-row" id="checkPromoCodeRow">
          <div className={`form-field${promoCode === '' ? '' : ' isNotEmpty'}`}>
            <label htmlFor="checkPromoCode">Promo Code</label>
            <input
              type="text"
              id="checkPromoCode"
              name="checkPromoCode"
              value={promoCode}
              onChange={(e) => {
                setPromoCodeValid(false)
                setPromoCodeError(undefined)
                setPromoCode(e.target.value)
              }}
            />
          </div>
        </div>
        {promoCodeValid ? (
          <div className="buttonProceed muted disabled">
            <p>Accepted</p>
          </div>
        ) : (
          <div className="buttonProceed muted" onClick={handleApply}>
            <p>Apply</p>
          </div>
        )}
      </form>
      {promoCodeError ? (
        <>
          <p style={{ color: 'rgb(var(--secondary-colour))', paddingLeft: 15 }}>
            {promoCodeError}
          </p>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}
